<template>
  <v-card>
    <v-card-text class="text--primary">
      <h1 class="my-2">{{ $t('reservations.dialogTitle') }}</h1>
      <h3 class="grey--text text--darken-1">
        {{ $t('sidenav.atGym', { gymName: gym.name }) }}
        <v-avatar>
          <v-img height="36" contain :src="gym.srcIcon" />
        </v-avatar>
      </h3>

      <p v-html="explanationFormatted" />

      <v-card flat v-if="areas.length > 1" class="mt-8 mb-4">
        <v-select
          v-model="selectedArea"
          :items="areas"
          :label="$t('reservations.selectArea')"
          item-text="name"
          return-object
          outlined
          hide-details
        />
      </v-card>
      <v-progress-linear v-if="areasLoading || reservationsLoading" indeterminate color="primary" class="my-4" />

      <template v-if="area && !reservationsLoading">
        <v-date-picker
          v-model="date"
          :locale="$i18n.locale"
          :first-day-of-week="moment.localeData()._week.dow"
          :landscape="$vuetify.breakpoint.smAndUp"
          reactive
          full-width
          color="primary"
          :min="today"
          :max="maxReservationDate"
          :picker-date.sync="monthStatsPickerDate"
          :events="monthStatsEvents"
        />
        <v-card outlined class="mt-4" :loading="slotsLoading">
          <v-toolbar flat>
            <v-toolbar-title v-text="$t('reservations.slotsHeader')" />
            <v-spacer />
            <v-btn icon @click="fetchSlots">
              <v-icon>tl-autorenew</v-icon>
            </v-btn>
          </v-toolbar>
          <v-list two-line>
            <div v-if="slotsLoading && !slots.length">
              <v-skeleton-loader
                :loading="slotsLoading"
                type="list-item-two-line"
                v-for="n in [1, 2, 3, 4, 5, 6]"
                :key="n"
              />
            </div>
            <v-list-item v-if="!slotsLoading && !slots.length">{{ $t('generic.noData') }}</v-list-item>
            <v-list-item-group v-model="slotsReservedModel" multiple color="primary" @change="updateSlotsReservedModel">
              <tl-reservations-slot
                v-for="slot in slots"
                :key="slot.id"
                :value="slot.id"
                :time-slot="slot"
                :reservations="reservations"
                :disabled="!!slotsReservedModel.length && !slotsReservedModel.includes(slot.id)"
                @reserve="openReserveDialog(slot)"
                @removed="onBookingRemoved($event)"
              />
            </v-list-item-group>
          </v-list>
          <tl-reservations-reserve-dialog ref="dialogReserve" />
        </v-card>
      </template>
      <div v-else style="height: 100px" />
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState } from 'vuex'
import axios from '@/services/axios'
import errorService from '@/services/error-service'
import tlReservationsMonthStatsMixin from '@/components/gym/admin/reservations/tl-reservations-month-stats.mixin'
import tlReservationsReserveDialog from './tl-reservations-reserve-dialog'
import tlReservationsSlot from './tl-reservations-slot'
import reservationsStore from '@/components/gym/admin/reservations/reservations.store'

export default {
  mixins: [tlReservationsMonthStatsMixin],
  components: {
    tlReservationsSlot,
    tlReservationsReserveDialog,
  },
  data: () => ({
    today: null,
    date: null,
    slots: [],
    reservations: [],
    slotsLoading: false,
    reservationsLoading: false,
    slotsReservedModel: [],
  }),
  computed: {
    ...mapState(['gym', 'user']),
    ...mapState('reservations', ['areas', 'area', 'areasLoading']),
    reservationSettings() {
      let settings = this.gym.revervation_settings_json && JSON.parse(this.gym.revervation_settings_json)
      return settings || {}
    },
    explanationFormatted() {
      let expl = this.reservationSettings.explanation || ''
      return expl.replace(/(?:\r\n|\r|\n)/g, '<br />')
    },
    selectedArea: {
      get() {
        return this.area
      },
      set(area) {
        this.$store.commit('reservations/setArea', area)
      },
    },
    monthStatsAreaId() {
      return this.area && this.area.id
    },
    slotsReserved() {
      let reservedSlotIds = this.reservations.filter(r => !r.cancelled_at).map(r => r.slot_id)
      return this.slots.filter(slot => reservedSlotIds.includes(slot.id)).map(slot => slot.id)
    },
    maxReservationDate() {
      if (!this.gym.reservations_book_advance_days) return null
      return this.moment()
        .add(this.gym.reservations_book_advance_days, 'd')
        .format('YYYY-MM-DD')
    },
  },
  beforeCreate() {
    this.$store.registerModuleOnce('reservations', reservationsStore)
  },
  created() {
    this.today = this.moment().format('YYYY-MM-DD')
    this.date = this.moment().format('YYYY-MM-DD')
    this.$store.commit('reservations/setArea')
    this.$store.dispatch('reservations/fetchAreas')
    this.fetchReservations()
  },
  watch: {
    monthStatsAreaId() {
      this.slots = []
      this.fetchSlots()
    },
    date() {
      this.slots = []
      this.fetchSlots()
    },
    slotsReserved() {
      this.updateSlotsReservedModel()
    },
  },
  methods: {
    fetchSlots() {
      if (!this.area) return
      this.slotsLoading = true
      axios
        .get(`/gyms/${this.gym.id}/slots`, {
          params: {
            date: this.date,
            reservation_area_id: this.area.id,
            slim: true,
          },
        })
        .then(({ data }) => (this.slots = data.filter(s => this.moment(s.end_at).isAfter())))
        .catch(errorService.toast)
        .finally(() => (this.slotsLoading = false))
    },
    fetchReservations() {
      if (!this.$store.getters['auth/isAuth']) return

      this.reservationsLoading = true
      axios
        .get(`/gyms/${this.gym.id}/reservations`)
        .then(({ data }) => (this.reservations = data))
        .catch(errorService.toast)
        .finally(() => (this.reservationsLoading = false))
    },
    updateSlotsReservedModel() {
      this.$nextTick(() => (this.slotsReservedModel = this.slotsReserved))
    },
    onBookingRemoved(removed_id) {
      this.reservations = this.reservations.filter(r => r.id != removed_id)
      this.fetchSlots()
      this.fetchReservations()
    },
    async openReserveDialog(slot) {
      if (!this.gym.reservations_guest_enabled && !this.$store.getters['auth/isAuth']) {
        return this.$store.dispatch('dialog/confirm', {
          title: this.$t('climbs.guestMsg'),
          text: this.$t('reservations.accountRequiredMsg'),
          ok: this.$t('auth.createAccount'),
          onSave: () => this.$router.pushSmart({ name: 'auth.login' }),
        })
      }
      let confirm = await this.$refs.dialogReserve.open(slot)
      if (!confirm) return
      this.fetchSlots()
      this.fetchReservations()
    },
  },
}
</script>
