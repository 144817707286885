<template>
  <v-dialog v-model="dialog" @keydown.esc="close" :fullscreen="$vuetify.breakpoint.xsOnly" max-width="500px" persistent>
    <v-form ref="form" lazy-validation @submit.prevent="submit">
      <v-card>
        <v-card-title class="headline">
          {{ $t('reservations.dialogTitle') }}
        </v-card-title>
        <v-card-text>
          <v-card outlined>
            <v-card-text class="text--primary text-center">
              <div class="title">{{ areaName }}</div>
              <div class="subtitle-1">{{ moment(slot.start_at).format('dddd, MMMM Do') }}</div>
              {{ moment(slot.start_at).format('LT') }} &mdash; {{ moment(slot.end_at).format('LT') }}
              <v-chip x-small outlined color="grey" class="ml-2">
                {{ moment(slot.end_at).preciseDiff(slot.start_at) }}
              </v-chip>
              <div class="caption" v-if="slot.checkin_end_at && slot.start_at != slot.checkin_end_at">
                {{ $t('reservations.checkinTime') }}: {{ moment(slot.start_at).format('LT') }} &mdash;
                {{ moment(slot.checkin_end_at).format('LT') }}
              </div>
              <v-alert class="mb-0 mt-2 pr-12" v-if="slot.details" type="info" text dense>{{ slot.details }}</v-alert>
            </v-card-text>
          </v-card>
        </v-card-text>

        <v-card-title>{{ $t('reservations.customerInformation') }}</v-card-title>
        <v-card-text>
          <v-row no-gutters>
            <v-col class="py-0">
              <v-text-field
                type="email"
                v-model="reservation.email"
                :disabled="!user.guest && !isAdmin"
                :label="$t('profile.info.email')"
                :rules="rulesEmail"
              />
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="6" class="pr-2">
              <v-text-field
                v-model="reservation.first_name"
                :label="$t('profile.info.firstName')"
                :rules="[rules.required]"
              />
            </v-col>
            <v-col cols="6" class="pl-2">
              <v-text-field
                v-model="reservation.last_name"
                :label="$t('profile.info.lastName')"
                :rules="isAdmin ? [] : [rules.required]"
              />
            </v-col>
            <v-col cols="12" v-if="slot.require_client_number">
              <v-text-field
                v-model="reservation.client_number"
                :label="reservationSettings.clientNumberLabel || $t('reservations.clientNumber')"
                :hint="reservationSettings.clientNumberHint"
                :rules="isAdmin ? [] : [rules.required]"
                persistent-hint
              />
            </v-col>
            <v-col cols="12" v-if="slot.require_password && !isAdmin">
              <v-text-field
                v-model="reservation.password"
                :label="$t('auth.password')"
                :type="showPwd ? 'text' : 'password'"
                :rules="[rules.required]"
                :append-icon="showPwd ? 'tl-visibility-off' : 'tl-visibility'"
                prepend-inner-icon="tl-lock"
                filled
                :hint="$t('reservations.passwordHint')"
                @click:append="showPwd = !showPwd"
              />
            </v-col>
          </v-row>
        </v-card-text>

        <template v-if="gym.reservations_spots_per_booking > 1 || isAdmin">
          <v-divider class="mx-4" />
          <template v-for="(associate, index) in reservation.associates">
            <v-card-text :key="index">
              <div class="d-flex">
                <div class="title text--primary">{{ $t('reservations.associate') }} {{ index + 1 }}</div>
                <v-spacer />
                <v-btn icon @click="removeAssociate(index)"><v-icon>tl-delete</v-icon></v-btn>
              </div>
              <v-row>
                <v-col cols="6" class="py-0">
                  <v-text-field
                    v-model="associate.first_name"
                    :label="$t('profile.info.firstName')"
                    :rules="[rules.required]"
                  />
                </v-col>
                <v-col cols="6" class="py-0">
                  <v-text-field
                    v-model="associate.last_name"
                    :label="$t('profile.info.lastName')"
                    :rules="[rules.required]"
                  />
                </v-col>
                <v-col cols="12" class="py-0" v-if="slot.require_client_number">
                  <v-text-field
                    v-model="associate.client_number"
                    :label="reservationSettings.clientNumberLabel || $t('reservations.clientNumber')"
                    :hint="reservationSettings.clientNumberHint"
                    :rules="[rules.required]"
                  />
                </v-col>
              </v-row>
            </v-card-text>
            <v-divider class="mx-4" :key="'divider_' + index" />
          </template>
          <v-card-actions>
            <v-btn :disabled="addSpotBtnDisabled" text @click="addAssociate">
              <v-icon left>tl-account-plus</v-icon>{{ $t('reservations.addAssociate') }}
            </v-btn>
          </v-card-actions>
        </template>

        <v-card-text>
          <v-row no-gutters v-if="!isAdmin" class="mb-4">
            <v-col cols="12">
              <v-checkbox
                :input-value="!!userSettings.sendConfirmation"
                color="primary"
                class="mt-0"
                :label="$t('reservations.sendConfirmation')"
                @click.native="setUserSetting('sendConfirmation', !userSettings.sendConfirmation)"
              />
            </v-col>
            <v-col cols="12">
              <v-checkbox
                v-model="reservation.accepted"
                color="primary"
                class="mt-0"
                :label="$t('reservations.acceptContact')"
                :rules="[rules.required]"
              />
            </v-col>
            <v-col cols="12" v-if="reservationSettings.reservationCheckbox">
              <v-checkbox v-model="reservationCheckbox" color="primary" class="mt-0" :rules="[rules.required]">
                <template v-slot:label>
                  <div v-html="checkboxLabelFormatted" />
                </template>
              </v-checkbox>
            </v-col>
          </v-row>
          <vue-recaptcha
            v-if="!isAdmin"
            :sitekey="recaptchaKey"
            :load-recaptcha-script="true"
            ref="recaptcha"
            size="invisible"
            badge="inline"
            @verify="onCaptchaVerified"
            @expired="onCaptchaExpired"
          />
        </v-card-text>
        <v-divider />

        <v-card-actions>
          <v-spacer />
          <v-btn text @click="close">{{ $t('generic.cancel') }}</v-btn>
          <v-btn text type="submit" color="primary" :loading="loading" :disabled="loading">
            {{ $t('reservations.book') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import axios from '@/services/axios'
import { mapState } from 'vuex'
import VueRecaptcha from 'vue-recaptcha'
import * as rules from '@/services/input-rules'
import errorService from '@/services/error-service'
import { sha256 } from 'js-sha256'

export default {
  components: {
    VueRecaptcha,
  },
  data: () => ({
    recaptchaKey: process.env.VUE_APP_RECAPTCHA_PUB_KEY,
    dialog: false,
    reservation: { associates: [] },
    reservationCheckbox: false,
    slot: {},
    loading: false,
    rules,
    showPwd: false,
    userSettings: {},
    userSettingsDefault: {
      version: 1,
      sendConfirmation: true,
    },
  }),
  computed: {
    ...mapState(['user', 'gym', 'isAdmin']),
    ...mapState('reservations', ['areas']),
    userSettingsStorageKey() {
      return `user_settings_reservations_${this.user.uid || 'guest'}`
    },
    reservationSettings() {
      let settings = this.gym.revervation_settings_json && JSON.parse(this.gym.revervation_settings_json)
      return settings || {}
    },
    checkboxLabelFormatted() {
      let label = this.reservationSettings.reservationCheckbox || ''
      return label.replace(/(?:\r\n|\r|\n)/g, '<br />')
    },
    areaName() {
      let area = this.areas.find(area => area.id == this.slot.reservation_area_id)
      return area && area.name
    },
    rulesEmail() {
      if (this.isAdmin) return []
      return [rules.required, rules.email]
    },
    addSpotBtnDisabled() {
      if (this.isAdmin) return false
      return this.reservation.associates.length + 1 >= this.gym.reservations_spots_per_booking
    },
  },
  methods: {
    initUserSettings() {
      let storage = JSON.parse(localStorage.getItem(this.userSettingsStorageKey) || '{}')
      this.userSettings = Object.assign({}, this.userSettingsDefault, storage)
    },
    setUserSetting(key, val) {
      this.userSettings[key] = val
      localStorage.setItem(this.userSettingsStorageKey, JSON.stringify(this.userSettings))
    },
    open(slot) {
      this.slot = slot
      this.reservation = Object.assign({ associates: [] }, { slot_id: slot.id })
      if (!this.user.guest && !this.isAdmin) {
        this.reservation.user_id = this.user.id
        this.reservation.email = this.user.email
        this.reservation.first_name = this.user.first_name
        this.reservation.last_name = this.user.last_name
      }
      this.initUserSettings()
      this.dialog = true
      return new Promise(resolve => (this.resolve = resolve))
    },
    close() {
      this.dialog = false
      setTimeout(() => {
        this.reservation = { associates: [] }
        this.slot = {}
        this.$refs.form && this.$refs.form.resetValidation()
      }, 300)
    },
    addAssociate() {
      this.reservation.associates.push({})
    },
    removeAssociate(index) {
      this.reservation.associates.splice(index, 1)
    },
    submit() {
      if (!this.$refs.form.validate()) return
      if (this.isAdmin) {
        this.onCaptchaVerified()
      } else {
        this.$refs.recaptcha.execute()
      }
    },
    async onCaptchaVerified(recaptchaToken) {
      let postData = Object.assign({}, this.reservation)
      if (postData.password) postData.password = sha256(postData.password)
      postData.associates_attributes = postData.associates
      postData.send_confirmation_mail = !!this.userSettings.sendConfirmation
      postData.recaptcha_token = recaptchaToken
      delete postData.associates

      this.loading = true
      try {
        await axios.post(`/gyms/${this.gym.id}/reservations`, postData)
        this.$store.dispatch('toast/success', this.$t('generic.success'))
        this.resolve(true)
        this.close()
        if (!this.isAdmin) this.$router.push({ name: 'bookings.my-bookings' })
      } catch (e) {
        errorService.toast(e).catch(() => {})
      } finally {
        this.$refs.recaptcha && this.$refs.recaptcha.reset()
        this.loading = false
      }
    },
    onCaptchaExpired() {
      this.$refs.recaptcha && this.$refs.recaptcha.reset()
    },
  },
}
</script>
