<template>
  <v-container fluid class="pa-0 pa-sm-3">
    <v-row no-gutters justify="center">
      <v-col cols="12" md="7">
        <router-view />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
import toolbarMixin from '@/components/layout/toolbar/toolbar.mixin'

export default {
  mixins: [toolbarMixin],
  head() {
    let description = this.$t('reservations.description', { gymName: this.gym.name })
    return {
      title: this.$t('reservations.title', { gymName: this.gym.name }),
      meta: [{ vmid: 'description', name: 'description', content: description }],
    }
  },
  computed: {
    ...mapState(['gym']),
    tlToolbarTitle() {
      return this.$t('sidenav.reservations')
    },
    tlToolbarTabs() {
      if (!this.$store.getters['auth/isAuth']) return []

      return [
        {
          name: this.$t('reservations.book'),
          to: { name: 'bookings.book' },
        },
        {
          name: this.$t('reservations.myBookings'),
          to: { name: 'bookings.my-bookings' },
        },
      ]
    },
  },
}
</script>
