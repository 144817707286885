<template>
  <div class="pa-3 pa-sm-0">
    <h1 class="mb-2">{{ $t('reservations.myBookings') }}</h1>

    <v-progress-linear indeterminate v-if="reservationsLoading" color="primary" />

    <div v-if="!reservationsLoading && !reservations.length">
      <div class="d-flex flex-nowrap justify-space-between align-center">
        <div>{{ $t('generic.none') }}</div>
        <v-btn icon @click="fetchReservations"><v-icon>tl-autorenew</v-icon></v-btn>
      </div>
      <v-btn class="mt-4" color="primary" :to="{ name: 'bookings.book' }">
        {{ $t('reservations.book') }}
      </v-btn>
    </div>

    <v-row v-for="reservation in futureReservations" :key="reservation.id">
      <v-col cols="12">
        <tl-my-reservations-item :reservation="reservation" @removed="fetchReservations" />
      </v-col>
    </v-row>

    <h2 class="my-2" v-if="expiredReservations.length">{{ $t('reservations.expiredBookings') }}</h2>
    <v-row v-for="reservation in expiredReservations" :key="reservation.id">
      <v-col cols="12">
        <tl-my-reservations-item :reservation="reservation" @removed="fetchReservations" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import axios from '@/services/axios'
import errorService from '@/services/error-service'
import tlMyReservationsItem from './tl-my-reservations-item'

export default {
  components: {
    tlMyReservationsItem,
  },
  data: () => ({
    reservations: [],
    reservationsLoading: false,
  }),
  computed: {
    futureReservations() {
      return this.reservations.filter(reservation => this.moment().isBefore(reservation.slot_end_at))
    },
    expiredReservations() {
      return this.reservations
        .filter(reservation => this.moment().isAfter(reservation.slot_end_at))
        .sort((a, b) => this.moment(b.slot_end_at).valueOf() - this.moment(a.slot_end_at).valueOf())
    },
  },
  mounted() {
    this.fetchReservations()
  },
  methods: {
    fetchReservations() {
      if (!this.$store.getters['auth/isAuth']) return

      this.reservations = []
      this.reservationsLoading = true
      axios
        .get(`/reservations`)
        .then(({ data }) => (this.reservations = data))
        .catch(errorService.toast)
        .finally(() => (this.reservationsLoading = false))
    },
  },
}
</script>
