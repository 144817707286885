import axios from '@/services/axios'
import errorService from '@/services/error-service'
import moment from 'moment'

const state = () => ({
  area: null,
  areas: [],
  areasLoading: false,
  date: moment().format('YYYY-MM-DD'),
  slots: [],
  slotsLoading: false,
})

const getters = {
  reservations(state) {
    return state.slots.map(slot => slot.reservations).flat()
  },
  checkedIns(state, getters) {
    return getters.reservations
      .filter(r => r.in_at && !r.out_at)
      .slice()
      .sort((a, b) => moment(a.slot_end_at).valueOf() - moment(b.slot_end_at).valueOf())
  },
}

const mutations = {
  setArea(state, area) {
    state.area = area
  },
  setAreas(state, areas) {
    state.areas = areas
  },
  setAreasLoading(state, loading) {
    state.areasLoading = loading
  },
  setDate(state, date) {
    state.date = date || moment().format('YYYY-MM-DD')
  },
  setSlots(state, slots) {
    state.slots = slots
  },
  setSlotsLoading(state, loading) {
    state.slotsLoading = loading
  },
}

const actions = {
  clear({ commit }) {
    commit('clearComponent')
    commit('toggle', false)
  },
  fetchAreas({ state, rootState, commit }) {
    commit('setAreasLoading', true)
    axios
      .get(`/gyms/${rootState.gym.id}/reservation_areas`)
      .then(({ data }) => {
        commit('setAreas', data)
        if (state.areas.length == 1) commit('setArea', state.areas[0])
      })
      .catch(errorService.toast)
      .finally(() => commit('setAreasLoading', false))
  },
  fetchSlots({ state, rootState, commit }) {
    commit('setSlotsLoading', true)
    axios
      .get(`/gyms/${rootState.gym.id}/slots`, {
        params: {
          date: state.date,
          reservation_area_id: state.area && state.area.id,
        },
      })
      .then(({ data }) => commit('setSlots', data))
      .catch(errorService.toast)
      .finally(() => commit('setSlotsLoading', false))
  },
  removeSlot({ state, commit }, slotId) {
    let newSlots = state.slots.filter(slot => slot.id != slotId)
    commit('setSlots', newSlots)
  },
  updateReservation({ rootState }, { reservation, update }) {
    return axios
      .put(`/gyms/${rootState.gym.id}/reservations/${reservation.id}`, update)
      .then(({ data }) => Object.assign(reservation, data))
      .catch(errorService.toast)
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
