import axios from '@/services/axios'

export default {
  data: () => ({
    monthStatsPickerDate: null,
    monthStats: {},
    monthStatsLoading: false,
  }),
  watch: {
    monthStatsAreaId() {
      this.monthStatsFetch()
    },
    monthStatsPickerDate() {
      this.monthStatsFetch()
    },
  },
  mounted() {
    this.monthStatsFetch()
  },
  methods: {
    monthStatsFetch() {
      let date = this.moment(this.monthStatsPickerDate || this.date).format('YYYY-MM-DD')
      this.monthStatsLoading = true
      let gym_id = this.$store.state.gym.id
      axios
        .get(`/gyms/${gym_id}/slots/month_stats`, { params: { date, reservation_area_id: this.monthStatsAreaId } })
        .then(({ data }) => (this.monthStats = data))
        .finally(() => (this.monthStatsLoading = false))
    },
    monthStatsEvents(date) {
      if (this.moment(date).isBefore()) return
      let stats = this.monthStats[date] || {}
      let remaining = stats.spots - stats.reservations
      if (!stats.spots) return
      if (remaining <= 0) return '#f00'
      if (remaining < 3) return '#FFA500'
      return '#0f0'
    },
  },
}
