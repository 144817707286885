<template>
  <v-card outlined :loading="removing" :disabled="moment().isAfter(slot.end_at)">
    <v-list-item>
      <v-list-item-avatar>
        <v-avatar><v-img :src="srcGymIcon"/></v-avatar>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title class="headline">{{ gym.name }}</v-list-item-title>
        <v-list-item-subtitle v-if="location">{{ location }}</v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>

    <v-divider />

    <v-card-text class="text--primary">
      <div class="title">{{ reservation.reservation_area.name }}</div>
      <div class="subtitle-1">{{ moment(slot.start_at).format('dddd, LL') | capitalize }}</div>
      {{ moment(slot.start_at).format('LT') }} &mdash; {{ moment(slot.end_at).format('LT') }}
      <v-chip x-small outlined color="grey" class="ml-2">
        {{ moment(slot.end_at).preciseDiff(slot.start_at) }}
      </v-chip>
      <div class="caption" v-if="slot.checkin_end_at && slot.start_at != slot.checkin_end_at">
        {{ $t('reservations.checkinTime') }}: {{ moment(slot.start_at).format('LT') }} &mdash;
        {{ moment(slot.checkin_end_at).format('LT') }}
      </div>
      <v-alert class="mb-0 mt-2 pr-12" v-if="slot.details" type="info" text dense>{{ slot.details }}</v-alert>
    </v-card-text>

    <v-divider />

    <v-card-text class="text--primary">
      <div v-if="reservation.is_last_minute" class="info--text">
        {{ $t('reservations.lastMinuteBooking') }}
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon small color="info" v-bind="attrs" v-on="on">tl-info</v-icon>
          </template>
          <span>{{ $t('reservations.lastMinuteBookingExpl') }}</span>
        </v-tooltip>
      </div>
      <div class="body-2">{{ $t('reservations.bookingNumber') }}: #{{ reservation.booking_number }}</div>
      <v-list-item class="pl-0">
        <v-list-item-title>{{ reservation.first_name }} {{ reservation.last_name }}</v-list-item-title>
        <v-list-item-action v-if="gym.reservations_self_checkout_enabled">
          <v-btn text :disabled="checkoutDisabled(reservation)" color="primary" @click="checkout(reservation.id)">
            {{ $t('reservations.checkout') }}
          </v-btn>
        </v-list-item-action>
      </v-list-item>

      <div v-if="reservation.associates.length" class="subtitle-2">Partners</div>
      <template v-for="(associate, index) in reservation.associates">
        <v-divider :key="`divider-${index}`" />
        <v-list-item :key="index" class="px-0">
          <v-list-item-content>
            <v-list-item-subtitle>{{ $t('reservations.associate') }} {{ index + 1 }}</v-list-item-subtitle>
            <v-list-item-title>{{ associate.first_name }} {{ associate.last_name }}</v-list-item-title>
          </v-list-item-content>
          <v-list-item-action v-if="gym.reservations_self_checkout_enabled">
            <v-btn text :disabled="checkoutDisabled(associate)" color="primary" @click="checkout(associate.id)">
              {{ $t('reservations.checkout') }}
            </v-btn>
          </v-list-item-action>
          <v-list-item-action v-if="moment().isBefore(associate.slot_start_at)">
            <v-btn icon :disabled="cancelDisabled" @click="removeAssociate(associate.id)">
              <v-icon>tl-delete</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </template>
    </v-card-text>

    <v-divider />

    <v-card-actions>
      <v-spacer />
      <v-btn text :disabled="cancelDisabled" @click="removeMain(reservation.id)">
        {{ $t('reservations.cancelBooking') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import axios from '@/services/axios'
import errorService from '@/services/error-service'

export default {
  props: {
    reservation: { type: Object, default: () => {} },
  },
  data: () => ({
    removing: false,
    checkingOut: false,
  }),
  computed: {
    loading() {
      return this.removing || this.checkingOut
    },
    slot() {
      return this.reservation.slot || {}
    },
    gym() {
      return this.reservation.gym || {}
    },
    srcGymIcon() {
      let srcImages = `${process.env.VUE_APP_URL_CDN}/images/gyms/${this.gym.id_name}/`
      return `${srcImages}logo_100x100.png?v=1`
    },
    location() {
      return [this.gym.address, this.gym.city].join(', ')
    },
    checkoutDisabled() {
      return reservation => {
        return this.loading || !reservation.in_at || !!reservation.out_at
      }
    },
    cancelDisabled() {
      return this.loading || this.moment().isAfter(this.slot.start_at)
    },
  },
  methods: {
    async removeAssociate(id) {
      let confirmed = await this.$store.dispatch('dialog/confirm', {
        maxWidth: 400,
        title: this.$t('reservations.removeAssociate'),
        text: this.$t('climbs.log.all.areYouSure'),
        ok: this.$t('generic.remove'),
      })
      if (confirmed) this.remove(id)
    },
    async removeMain(id) {
      let confirmed = await this.$store.dispatch('dialog/confirm', {
        maxWidth: 400,
        title: this.$t('reservations.cancelBooking'),
        text: this.$t('climbs.log.all.areYouSure'),
        ok: this.$t('reservations.cancelBooking'),
      })
      if (confirmed) this.remove(id)
    },
    remove(id) {
      this.removing = true
      axios
        .put(`/gyms/${this.reservation.gym_id}/reservations/${id}/cancel`)
        .then(() => {
          this.$store.dispatch('toast/success', this.$t('generic.success'))
          this.$emit('removed')
        })
        .catch(errorService.toast)
        .finally(() => (this.removing = false))
    },
    checkout(id) {
      this.checkingOut = true
      let update = { out_at: this.moment().toISOString() }
      axios
        .put(`/gyms/${this.reservation.gym_id}/reservations/${id}`, update)
        .then(() => {
          this.$store.dispatch('toast/success', this.$t('generic.success'))
          this.$emit('removed')
        })
        .catch(errorService.toast)
        .finally(() => (this.checkingOut = false))
    },
  },
}
</script>
