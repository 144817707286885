<template>
  <v-list-item
    :value="timeSlot.id"
    :disabled="!reservation && (disabled || !spotsAvailable || tooFarInAdvance)"
    @click="onClick"
  >
    <v-list-item-content>
      <v-list-item-title>
        {{ moment(timeSlot.start_at).format('LT') }} &mdash; {{ moment(timeSlot.end_at).format('LT') }}
        <v-chip small outlined color="grey" class="ml-4">
          {{ moment(timeSlot.end_at).preciseDiff(timeSlot.start_at) }}
        </v-chip>
        <v-tooltip v-if="!timeSlot.live" bottom>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on" color="warning" class="ml-2">tl-visibility-off</v-icon>
          </template>
          <span>This slot is not visible to customers</span>
        </v-tooltip>
      </v-list-item-title>
      <v-list-item-subtitle v-if="timeSlot.details" class="info--text">{{ timeSlot.details }}</v-list-item-subtitle>
      <v-list-item-subtitle v-if="timeSlot.require_password">
        {{ $t('auth.password') }}<v-icon small class="ml-1">tl-lock</v-icon>
      </v-list-item-subtitle>
      <v-list-item-subtitle
        v-if="!hideSpotsAvailable"
        :class="spotsAvailable < 3 && timeSlot.spots > 5 ? 'warning--text' : ''"
      >
        {{ $t('reservations.spotsLeft', { spots: spotsAvailable }) }}
      </v-list-item-subtitle>
    </v-list-item-content>

    <v-list-item-action class="ml-0">
      <v-btn v-if="reservation" text :loading="removing">{{ $t('reservations.cancelBooking') }}</v-btn>
      <div v-else-if="tooFarInAdvance" class="warning--text pl-4">
        {{ $t('reservations.available') + ' ' + momentBookable.fromNow() }}
      </div>
      <v-btn v-else-if="!spotsAvailable" text color="warning">{{ $t('reservations.fullyBooked') }}</v-btn>
      <v-btn v-else :disabled="disabled" text color="primary">{{ $t('reservations.book') }}</v-btn>
    </v-list-item-action>
  </v-list-item>
</template>

<script>
import { mapState } from 'vuex'
import axios from '@/services/axios'
import errorService from '@/services/error-service'

export default {
  props: {
    timeSlot: { type: Object, default: () => ({}) },
    reservations: { type: Array, default: () => [] },
    disabled: { type: Boolean, default: false },
  },
  data: () => ({
    removing: false,
    momentReactiveInterval: null,
    momentReactive: null,
  }),
  computed: {
    ...mapState(['gym', 'user']),
    spotsAvailable() {
      return Math.max(0, this.timeSlot.spots - this.timeSlot.spots_booked)
    },
    hideSpotsAvailable() {
      return this.reservationSettings.hideCapacity && this.spotsAvailable > 1 + this.gym.reservations_spots_per_booking
    },
    reservation() {
      return this.reservations.find(r => r.slot_id == this.timeSlot.id && !r.cancelled_at)
    },
    reservationSettings() {
      let settings = this.gym.revervation_settings_json && JSON.parse(this.gym.revervation_settings_json)
      return settings || {}
    },
    momentBookable() {
      if (this.gym.reservations_open_slots_at_fixed_time) {
        let date = this.moment(this.timeSlot.start_at)
          .subtract(this.gym.reservations_book_advance_days, 'days')
          .format('YYYY-MM-DD')
        let time = this.moment(this.gym.reservations_open_slots_at_time).format('HH:mm')
        return this.moment(date + 'T' + time)
      } else {
        return this.moment(this.timeSlot.start_at).subtract(this.gym.reservations_book_advance_days, 'days')
      }
    },
    tooFarInAdvance() {
      return this.momentBookable > (this.momentReactive || this.moment())
    },
  },
  created() {
    this.momentReactiveInterval = setInterval(() => {
      this.momentReactive = this.moment()
    }, 1000)
  },
  beforeDestroy() {
    clearInterval(this.momentReactiveInterval)
  },
  methods: {
    onClick() {
      this.reservation ? this.removeReservation() : this.$emit('reserve')
    },
    async removeReservation() {
      let confirmed = await this.$store.dispatch('dialog/confirm', {
        maxWidth: 400,
        title: this.$t('reservations.cancelBooking'),
        text: this.$t('climbs.log.all.areYouSure'),
        ok: this.$t('reservations.cancelBooking'),
      })
      if (!confirmed) return

      this.removing = true
      axios
        .put(`/gyms/${this.gym.id}/reservations/${this.reservation.id}/cancel`)
        .then(() => {
          this.$store.dispatch('toast/success', this.$t('generic.success'))
          this.$emit('removed', this.reservation.id)
        })
        .catch(errorService.toast)
        .finally(() => (this.removing = false))
    },
  },
}
</script>
